.adds
{
    margin-top: 20px; 
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;


    button
    {
        margin-left: 20px;
    }

}