.table {
  margin-top: 30px;

  &--grid {
    display: grid;
  }

  &-header {
    margin-bottom: 10px;
  }

  &-track {
    margin: 10px 0;

    // &:last-child {
    //   margin-bottom: 120px;
    // }

    border: 1px solid $lightGray;
  }

  &-cell {
    padding: 10px;

    @include flex(flex-start, center);

    @include font($black, 16px);

    position: relative;

    margin-right: -20px;

    transition: all 0.3s ease;

    // cursor: pointer;

    &:hover {
      background-color: $lightGray;
    }

    &--header {
      color: #666666;

      border: 0;
    }

    &--control {
      @include flex();
    }

    &:hover &-modal {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }

    & p {
      width: 100%;
      max-height: 75px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-modal {
      visibility: hidden;
      opacity: 0;
      padding: 10px;

      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 1;

      border-radius: 0 0 5px 5px;

      background-color: $lightGray;

      transition: all 0.3s ease;

      &-content {
        margin: 10px 0;
      }

      &-label {
        @include font($black, 14px, 500);
      }

      &-text {
        max-height: inherit !important;
        text-overflow: unset !important;
        overflow-wrap: break-word;
        white-space: pre-wrap !important;
      }
    }

    &-img {
      width: 70px;
      height: 70px;

      object-fit: cover;

      border-radius: 5px;
    }
  }

  &--home {
    grid-template-columns: 3fr 2fr 3fr 2fr 2fr;
  }

  &--orders {
    grid-template-columns: 5% repeat(8, 11%) 7%;
  }

  &--categories {
    grid-template-columns: repeat(8, 1fr);
  }

  &--products {
    grid-template-columns: 5% 10% 12% 10% 14% 10% 8% 12% 10% 7%;
  }

  &--restaurants {
    grid-template-columns: 16% 16% 16% 21% 21% 10%;
  }

  &--booking {
    grid-template-columns: 5% 16% 15% 18% 12% 29% 5%;
  }

  &--referals {
    grid-template-columns: 18% 7% 12% 12% 20% 21% 10%;
  }

  &--users {
    //grid-template-columns: 5% 25% 25% 18% 18% 9%;
    grid-template-columns: 5% 20% 20% 15% 15% 15% 10%;
  }

  &--birthdays {
    grid-template-columns: 5% 20% 20% 18% 18% 19%;
  }
  &--staff {
    grid-template-columns: 5% 20% 20% 20% 15% 10% 10%;
  }

  &--roles {
    grid-template-columns: 10% 35% 20% 25% 10%;
  }

  &--sales {
    grid-template-columns: 5% 15% 10% 15% 10% 15% 15% 15%;
  }

  &--feedback {
    grid-template-columns: 5% 20% 20% 10% 20% 15% 10%;
  }

  &--feedback_brief {
    grid-template-columns: 10% 25% 10% 20% 20% 15%;
  }

  &--stocks {
    grid-template-columns: 5% 15% 15% 25% 30% 10%;
  }

  &--news {
    grid-template-columns: 5% 10% 15% 20% 20% 20% 10%;
  }

  &--options {
    grid-template-columns: 5% 15% 15% 10% 15% 30% 10%;
  }

  &--archive {
    grid-template-columns: 6% 12% 11% 12% 13% 10% 17% 15% 3%;
  }
}

.label-text {
  position: relative;
  word-wrap: break-word;
}

.label-wrapper {
  word-break: break-word;
}

.pagination-wrapper {
  &--slider {
    width: 100%;
    .wrap {
      width: 80%;
      max-width: 500px;
      margin: 0 auto;
      height: 45px;
      overflow: hidden;
      overflow-x: scroll;
    }

    .wrap::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    .wrap::-webkit-scrollbar-button {
      background-color: #6dc53e;
    }

    .wrap::-webkit-scrollbar-track {
      background-color: #35b537;
    }
    .wrap::-webkit-scrollbar-track-piece {
      background-color: #ffffff;
    }

    .wrap::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: rgb(235, 197, 29);
      border-radius: 3px;
    }
  }
}

.new {
  background-color: red;
}

.working {
  background-color: yellow;
}

.done {
  background-color: green;
}
