.dragContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: grab;
  margin-top: 20px;
}

.dragCard {
  width: 200px;
  max-height: 150px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px black solid;
  padding: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
