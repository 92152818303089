.input-list {
    padding: 10px;
  
    width: 350px; 

    font-size: 16px;
  
    border: 1px solid #e6e6e6;;
    border-radius: 5px;
  
    outline: none;
  
    background-color: #FCFCFC;
  
    transition: all .2s ease;
  
    cursor: pointer;

    &.invalid {
      border-color: #d46262;
    }
  }