.image__list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 15px;
    margin: 0;
    padding: 0;
}
.image__item{
    position:relative;
    button{
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top:0;
        right:0;
        width: 60px;
        height: 60px;
        background-color: #ffffff;
        opacity: 0.4;
        border:none;
        outline:none;
        border-radius: 0 5px 0 5px;
        cursor:pointer;
        img{
            width: 40px;
            height: 40px;;
        }
    }

}

 .image__product {
    object-fit: cover;
    width: 350px !important;
    height: 250px!important;
    display: block;
    }

    .select__category{
        width: 350px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        outline: none;
        background-color: #FCFCFC;
        transition: all 0.2s ease;
        cursor: pointer;
        height: 48px;
    }