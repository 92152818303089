.page-item {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: row;
}

.trash-icon {
    width: 25px; 
    height: 25px; 
    margin-top: 6px;
    margin-left: 10px;
}