body {
  color: $black;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.border {
  border: 1px solid $lightGray;
}

.flex {
  @include flex();
}

.margin {
  &--sides {
    margin: 0 15px;
  }
}


.label {

  &-wrapper {
    margin: 25px 0;

    @include flex(flex-start);

    & img {
      width: 100px;
      height: 100px;

      border-radius: 5px;
    }
  }

  &-title {
    min-width: 225px;
    margin-right: 20px;

    &.small {
      font-size: 16px;
    }

    &.medium {
      font-size: 18px;
    }

    &.large {
      font-size: 20px;
    }

    &.normal {
      font-weight: normal;
    }

    &.bold {
      font-weight: bold;
    }
  }

  &-text {
    @include font($lightGreen, 16px, 400);
  }
}

.pagination {

  &-wrapper {
    @include flex();
  }

  &-link {
    margin: 0 5px;
    padding: 5px 8px;

    @include font($green, 16px, 500, none);

    border-radius: 5px;

    transition: all .3s linear;

    &:hover {
      color: $white;
      background-color: lighten($lightGreen, 20%);
    }

    &.active {
      color: $white !important;
      background-color: $lightGreen !important;
    }
  }
}


.sort-bar {

  &-wrapper {
    width: 100%;
    margin: 50px 0;
    cursor: pointer;

    @include flex(space-between);
  }

  &-container {
    @include flex();
  }

  &-title {
    margin-right: 10px;
    @include font($black, 16px, 500);
  }
}


.block {

  &-container {
    margin: 35px 0;
    padding: 10px 20px;

    border-radius: 5px;
  }

  &-title {
    margin-bottom: 30px;

    @include font($black, 22px, 500);
  }
}


.nesting {

  &-arrow {
    transition: all .3s ease;

    &.active {
      transform: rotate(180deg);
    }
  }

  &-dot {
    width: 10px;
    height: 10px;
  }
}

.comma {
  margin-right: 10px;

  @include font($green, 18px, 500);
}

.star {
  width: 25px !important;
  height: 25px !important;

  margin-right: 15px;
}

.delete {
  width: 25px !important;
  height: 25px !important;

  margin-left: 10px;
}


.title {
  color: $black;

  &.small {
    font-size: 16px;
  }

  &.medium {
    font-size: 18px;
  }

  &.large {
    font-size: 20px;
  }

  &.normal {
    font-weight: normal;
  }

  &.bold {
    font-weight: bold;
  }
}

.feedback {

  &-wrapper {
    padding: 10px;

    border-radius: 5px;

    background-color: $lightGray;
  }
}

.similar {

  &-item {
    margin: 5px 0;
    padding: 5px 0 5px 5px;

    @include flex(space-between);

    border-radius: 5px;

    & a {
      margin-right: 5px;

      font-size: 16px;
    }
  }
}

.flex-news-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-container
{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px 
}