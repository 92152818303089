.btn {
  border: 0;
  outline: none;

  background-color: $darkGreen;

  transition: all 0.2s ease;

  cursor: pointer;

  &--lang {
    margin: 10px 10px 10px 0;
    padding: 5px 25px;

    border-radius: 5px;

    box-shadow: 2px 2px 8px 0px $gray;

    background-color: $lightGray;

    transition: all 0.3s ease;

    &.hover {
      box-shadow: none;
      opacity: 0.9;
    }

    &.active {
      color: $cream;
      box-shadow: none;
      background-color: $green;
    }
  }

  &--auth {
    width: 100%;

    padding: 20px 0;

    border-radius: 0 0 10px 10px;

    outline: none;

    background-color: $lightGray;

    &:hover {
      opacity: 0.9;
    }

    &.active {
      opacity: 0.9;
    }
  }

  &--hide {
    width: 30px;
    height: 30px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    border-color: $gray;
    border-style: solid;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-radius: 0 0 100% 0;

    background-color: $cream;

    &.hover {
      opacity: 0.9;
    }

    &.active img {
      transform: translate(-35%, -35%) rotate(180deg);
    }

    & img {
      position: absolute;
      top: 35%;
      left: 35%;

      transform: translate(-35%, -35%);

      transition: all 0.2s ease;
    }
  }

  &--default {
    width: 220px;
    height: 50px;

    &:first-child {
      margin-right: 15px;
    }

    @include flex();

    @include font($cream, 18px, bold);

    border-radius: 5px;

    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
    }

    &:first-child {
      margin-left: 0;
    }

    &--green {
      background: linear-gradient(180deg, #35b537 0%, #088d0a 100%);
    }

    &--yellow {
      background: linear-gradient(180deg, #d5d814 0%, #ff980c 100%);
    }

    &--red {
      background: linear-gradient(180deg, #ff0c0c 0%, #ac0000 100%);
    }

    &.right {
      float: right;
    }
  }

  &--image {
    margin-left: 10px;
    padding: 5px;

    @include flex();

    background-color: transparent;

    transition: all 0.2s ease;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      transform: scale(1.05);
    }

    & img {
      height: 22px;
    }
  }

  &--back {
    @include flex(flex-start);

    line-height: 0.8;

    background-color: transparent;

    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }

    & img {
      margin-right: 15px;
    }
  }

  &--link {
    @include font($green, 18px, 500);

    position: relative;

    &:hover:before {
      width: 100%;
    }

    &::before {
      width: 0;
      height: 1px;

      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;

      background-color: $green;

      transition: all 0.3s ease;
    }
  }

  &--similar {
    border-left: 1px solid $lightGray;
    background-color: transparent;

    transition: all 0.3s ease;

    &:hover img {
      transform: scale(1.1);
    }

    & img {
      width: 12px;
      height: 12px;
    }
  }

  &--excel-btn {
    width: 220px;
    height: 48px;
    cursor: pointer;
    border: 0;
    position: absolute;
    right: 40px;
    top: 136px;

    @include flex();

    @include font($cream, 18px, bold);

    border-radius: 5px;

    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
    }

    &:first-child {
      margin-left: 0;
    }

    background: green;

    &.right {
      float: right;
    }
  }

  &--clear-btn {
    width: 220px;
    height: 48px;
    cursor: pointer;
    border-radius: 5px;
    border: 0;
    position: absolute;
    right: 40px;
    top: 80px;
    background: red;
    color: #fff;
  }
}

.clear-filter {
  width: 280px;
  height: 47px;
  font-size: 15px;
  margin-right: 0px;
}
